<template>
  <v-container fluid class="pa-6">
    <v-row align="center" justify="center">
      <v-col md="6">
        <v-alert :value="alert" type="error" class="mb-3">
          <span>{{ $t('auth.expiredRecoveryLink') }} </span>
          <router-link class="white--text" :to="'password-recovery'">
            {{ $t('auth.requestNewRecoveryLink') }}
          </router-link>
        </v-alert>
        <v-card>
          <v-container class="pa-6">
            <h4
              class="text-h5 text-center mb-3 font-weight-light text-capitalize"
            >
              {{ $t('auth.resetPassword') }}
            </h4>
            <reset-password-form :reset="resetPassword"></reset-password-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ResetPasswordForm from '../components/auth/ResetPasswordForm';
import {
  VERIFY_RECOVERY_PASSWORD_LINK,
  RESET_PASSWORD
} from '../store/auth/types';
import apiErrors from '../enums/api.errors';

export default {
  name: 'reset-password',
  components: {
    ResetPasswordForm
  },
  data() {
    return {
      alert: false
    };
  },
  created() {
    this.verifyRecoveryLink(this.$route.query.rt);
  },
  methods: {
    async verifyRecoveryLink(rt) {
      try {
        await this.$store.dispatch(VERIFY_RECOVERY_PASSWORD_LINK, { rt });
      } catch (err) {
        this.alert = true;
      }
    },
    async resetPassword(payload) {
      try {
        await this.$store.dispatch(RESET_PASSWORD, {
          ...payload,
          rt: this.$route.query.rt
        });

        this.$notify({
          type: 'success',
          text: this.$i18n.t('auth.passwordResetSuccessfully')
        });

        this.$router.push({ path: '/login' });
      } catch (err) {
        if (err.message === apiErrors.passwordRecoveryLinkExpired) {
          this.alert = true;
        }
      }
    }
  }
};
</script>
