<template>
  <v-form ref="resetPasswordForm">
    <v-text-field
      solo
      v-model="passwordData.newPassword"
      :rules="[rules.required, rules.passwordMinLength]"
      type="password"
      :label="$t('common.newPassword')"
      prepend-inner-icon="mdi-lock"
    ></v-text-field>
    <v-text-field
      solo
      v-model="passwordData.confirmPassword"
      :rules="[
        rules.required,
        rules.equalWithPassword(passwordData.newPassword)
      ]"
      type="password"
      :label="$t('common.confirmNewPassword')"
      prepend-inner-icon="mdi-lock"
    ></v-text-field>
    <div class="text-right">
      <v-btn dark color="app-blue" @click="save">
        {{ $t('auth.resetPassword') }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import validators from '../../common/validators';

const PASSWORD_MIN_LENGTH = 6;

export default {
  name: 'reset-password-form',
  props: {
    reset: Function
  },
  data() {
    return {
      passwordData: {
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        equalWithPassword: prop =>
          validators.equal(prop, this.$i18n.t('common.passwordMismatch')),
        passwordMinLength: validators.min(
          PASSWORD_MIN_LENGTH,
          this.$i18n.t('common.minLength', {
            value: PASSWORD_MIN_LENGTH
          })
        )
      }
    };
  },
  methods: {
    async save() {
      if (!this.$refs.resetPasswordForm.validate()) {
        return;
      }

      this.reset(this.passwordData);
    }
  }
};
</script>
